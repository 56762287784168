import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import dafina_img_1 from '../../../images/DafinaWard.jpg';
import dafina_img_1_mobile from '../../../images/DafinaWard_mobile.jpg';

const fullName = "Dafina Ward";

const copyList = [

    {
        type: "image",
        content: {
            src: dafina_img_1,
            mobileSrc: dafina_img_1_mobile,
            alt: "Dafina Ward",
            caption: "Dafina Ward"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title\">A conversation with Dafina Ward, Esq., attorney and Executive Director of the Southern AIDS Coalition"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Champion"
    // },
    {
        type: "text",
        content: `It’s never been a good idea to mess with Dafina Ward, Esq., the Executive Director of the Southern AIDS 
        Coalition.* She first learned to confront injustice at home. Her father was a lawyer, and her mother was 
        a professor who “was the architect of all of the writing for the Birmingham Civil 
        Rights Institute,” she says. “As they were developing the exhibits, she was writing the 
        text and telling the story on the walls of our dining room. I felt surrounded and empowered by 
        history, and like, if something’s wrong, you’re supposed to challenge it.”`
    },
    {
        type: "text",
        content: "Even so, by the time she was a teen, Ward found herself in an abusive relationship. “I was wanting to shift systems that are problematic while also having my own personal struggles. I learned early on just how complex life experiences are.” The abuse continued until, while attending Clark Atlanta University, a fellow Black woman intervened. “My roommate helped me see that it wasn’t my fault. She stood in strength when I couldn’t and told him, ‘You can’t see her.’” From there, “I really felt a rebirth.”"
    },

    {
        type: "text",
        content: "She went on to pursue a career in law, working with the Southern Poverty Law Center on a case for incarcerated men being denied healthcare. One of the men wasn’t being given HIV medication. “That was the first time I learned about the challenges around HIV, particularly in communities in the South.” Years later, Ward was a new mom practicing law and realized she needed more. “I was like, I can’t justify being away from my beautiful child doing bankruptcies and contracts. I want to do work where I can come home and feel good about the time I spent away from my baby. Spurred by her early experience fighting for HIV/AIDS care, she knew what to do. “I went both feet in and fell in love with the work.”"
    },
    {
        type: "text",
        content: `In her current role, Ward is committed to helping to end the HIV epidemic in 
        the South by challenging discriminatory policies and supporting 
        impactful programs. Because, as far as she’s concerned, the South 
        is Ground Zero. “If you want to help end the HIV epidemic in this country,” she says, “it is mandatory 
        to end it here.” While her impact on the region is far reaching, one area 
        she’s proudest of is “the millions in grants we’ve been able to put into 
        the hands of primarily Black- and brown-led organizations in southern states. Organizations where 
        they knew what their communities needed and wanted to be responsive.” Through building 
        the capacity of these programs, she’s helping to change the course of HIV in the South.`
    },
    {
        type: "blockquote",
        content: "“If you want to end the HIV epidemic in this country, it is mandatory to end it [in the South].”",
        author: fullName
    },
    {
        type: "text",
        content: "“There’s so much work to do it’s easy to feel like you’re not making a difference,” says Ward. But that doesn’t make her work any less fulfilling, especially for someone who always wanted to right wrongs. “The most meaningful recognition for me has been when someone I’ve touched along this journey tells me that my effort and support made a difference. It’s just knowing that I have played a part in bettering someone’s life.”"
    },
    {
      type: "disclaimer",
    },
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">DAFINA WARD"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">Southern AIDS Coalition"
    // },
    {
        type: "championsofchangelandinglink",
          content: "<a class=\"championsofchangelandinglink\" href=\"/championsofchange\">Learn more about all our Champions of Change honorees "
    },

]

class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}
        
        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Dafina Ward, The Champion"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}



      </div>
    );
  }
}

export default Content;
